<template>
  <div
    class="base-tooltip"
  >
    <el-tooltip
      :content="tooltipText"
      :disabled="!tooltipText"
      :placement="placement"
      :effect="color"
      raw-content
    >
      <slot>
        <div class="base-tooltip__trigger"></div>
      </slot>
    </el-tooltip>
  </div>
</template>

<script>

export default {
  name: 'BaseTooltip',
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'dark',
    },
    placement: {
      type: String,
      default: 'bottom-end',
    },
  },
};
</script>

<style lang="scss">
.base-tooltip__trigger{
  width: 16px;
  height: 16px;
  background-image: url(/public/img/renova-dashboard/icons/help-circle.svg);
  cursor: pointer;
}
.el-tooltip__popper.is-dark {
  color: #FFF;
  border-radius: 8px;
  background: var(--solid-black-purple-09) !important;
  transform: translateX(5px);
  transition: .3s;
  padding: 12px 16px;
  max-width: 261px;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  z-index: 50000 !important;
  .popper__arrow {
    border-bottom-color: var(--solid-black-purple) !important;
    opacity: 0.9;
    &::after {
      border-bottom-color: var(--solid-black-purple) !important;
      opacity: 0.9;
    }
  }
}
.el-tooltip__popper.is-light {
  color: var(--solid-black-purple);
  border-radius: 8px;
  background: #FFF !important;
  transform: translateX(5px);
  transition: .3s;
  padding: 12px 16px;
  max-width: 261px;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border: 1px solid var(--solid-lavender-grey) !important;
  z-index: 5000 !important;
  box-shadow: 0px 2px 8px 0px rgba(24, 7, 38, 0.15);

  .popper__arrow {
    border-bottom-color: #FFF !important;
    &::after {
      border-bottom-color: #FFF !important;
    }
  }
}

</style>
