<template>
  <BaseSideModal :width="'800px'" @close="onClose" :confirm-close-dialog="true">
    <template #title>
      {{
        programEdit ? $t("adminTitles.editPlan") : $t("adminTitles.createNewIndividualPlan")
      }}
    </template>

    <el-form :model="form" :rules="rules" ref="form" label-position="top" class="modal-plan__form">
      <el-form-item class="modal-plan__input" :label="$t('adminTitles.planTitle')" prop="name">
        <el-input :placeholder="$t('adminTitles.createPlanTitle')" v-model="form.name" />
      </el-form-item>

      <el-form-item
        class="modal-plan__input"
        :label="$t('adminTitles.insuranceCompany')"
        prop="insuranceCompanyId"
      >
        <el-select
          v-model="form.insuranceCompanyId"
          filterable
          clearable
          :placeholder="$t('placeholders.notSelected')"
          :no-data-text="$t('adminTitles.insuranceCompanyNotFound')"
        >
          <el-option
            v-for="item in activeInsuranceCompanies"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        class="modal-plan__input"
        :label="$t('adminTitles.mainProgramLanguage')"
        prop="defaultLocale"
      >
        <el-select
          v-model="form.defaultLocale"
          filterable
          :placeholder="$t('adminTitles.createPlanLanguage')"
        >
          <el-option v-for="item in languages" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
        <BaseTooltip
          class="program__input-tooltip"
          :tooltip-text="$t('adminTitles.createPlanLanguageTooltip')"
        />
      </el-form-item>
      <el-form-item
        class="modal-plan__input"
        :label="$t('programTypes.InsuranceType')"
        prop="insuranceTypeSave"
      >
        <BaseDropdown
          :selected-options="form.insuranceTypeSave ? [form.insuranceTypeSave] : []"
          :multiple="false"
          :width="'100%'"
          :clearable="true"
          :placeholder="$t('programTypes.SelectInsuranceType')"
          :options="INSURANCE_TYPES_OPTIONS"
          @changeOptions="form.insuranceTypeSave = $event.length ? $event[0] : null"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <BaseButton type="outline" @click="onClose">
        {{ $t("buttons.close") }}
      </BaseButton>
      <BaseButton :loading="btnLoading" @click="submitForm">
        {{ programEdit ? $t("buttons.save") : $t("buttons.create") }}
      </BaseButton>
    </template>
  </BaseSideModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { PROGRAM_SAVE } from '@/store/actions/programs';
import BaseSideModal from '@/views/RenovaDashboard/components/ui-kit/BaseSideModal/BaseSideModal';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import BaseDialog from '@/views/RenovaDashboard/components/ui-kit/BaseDialog';
import BaseTooltip from '@/views/RenovaDashboard/components/ui-kit/BaseTooltip';
import {
  PLAN_TYPES,
  PlanTypeOptions,
} from '@/views/RenovaDashboard/ProgramsParts/components/constants';
import BaseDropdown from '@/views/RenovaDashboard/components/ui-kit/Dropdowns/BaseDropdown';

export default {
  name: 'ModalCreateNewIndividualPlan',
  components: {
    BaseDropdown,
    BaseTooltip,
    BaseButton,
    BaseSideModal,
  },
  mixins: [PlanTypeOptions],
  emits: ['close', 'saved'],
  props: {
    programEdit: {
      type: Object,
      default: null,
    },
    insuranceCompanyId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: '',
        insuranceCompanyId: null,
        defaultLocale: null,
        insuranceTypeSave: null,
      },
      btnLoading: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t('adminTitles.FieldIsRequired'),
          },
        ],
        insuranceTypeSave: [
          {
            required: true,
            message: this.$t('adminTitles.FieldIsRequired'),
          },
        ],
      },
      showConfirmationDialog: false,
    };
  },
  created() {
    if (this.programEdit) {
      this.form = {
        packageId: this.programEdit.packageId,
        name: this.programEdit.name,
        insuranceCompanyId: this.programEdit.insuranceCompany?.id,
        defaultLocale: this.programEdit.defaultLocale,
        insuranceTypeSave: this.programEdit.insuranceType,
      };
    }
  },
  watch: {
    activeInsuranceCompanies(val) {
      if (val.length === 1) {
        this.form.insuranceCompanyId = val[0].id;
      }
    },
  },
  computed: {
    ...mapGetters([
      'programDetails',
      'activeInsuranceCompanies',
      'languages',
    ]),
    datepickerOptions() {
      return {
        firstDayOfWeek: 1,
      };
    },
    haveUnsavedData() {
      return Object.values(this.form).some((value) => value);
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.$store
            .dispatch(PROGRAM_SAVE, {
              ...this.form,
              insurancePlanTypeSave: PLAN_TYPES.INDIVIDUAL,
            })
            .then((res) => {
              this.$emit('saved');
              this.$router.push(`/admin/programs/${res.packageId}`);
              this.btnLoading = false;
              this.$notify.success({message: `${this.$t('adminTitles.success')}`});
            })
            .catch(() => {
              this.btnLoading = false;
              this.$notify.error({message: `${this.$t('adminTitles.someError')}`});
            });
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .el-select {
  display: inline;
}

.modal-plan__form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.modal-plan__input {
  flex-basis: calc(50% - 8px);
  position: relative;
}

.program__input-tooltip {
  position: absolute;
  right: 0;
  top: -22px;
}
</style>
