import axios from 'axios';
import i18n from '@/i18n';
import {
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DETAILS_REQUEST,
  EMPLOYEE_DETAILS_SUCCESS,
  EMPLOYEE_SAVE_REQUEST,
  EMPLOYEE_SAVE_SUCCESS,
  EMPLOYEE_SEND_WELCOME_EMAIL_REQUEST,
  EMPLOYEE_SEND_WELCOME_EMAIL_SUCCESS,
  EMPLOYEES_LIST_REQUEST,
  EMPLOYEES_LIST_SUCCESS,
} from '@/store/actions/employees';
import { USER_ROLES } from '@/store/modules/user';

const state = {
  employeesList: {
    items: null,
    loading: false,
  },
  employeeDetails: null,
};

const getters = {
  employeesList: () => state.employeesList,
  employeeDetails: () => state.employeeDetails,
};
export const fetchEmployeesList = (filters = {}) => axios.post(
  '/insurance/authorization/grant/list',
  {
    ...filters,
    roles: undefined,
  },
  { headers: { 'X-Locale': i18n.locale } },
).then((res) => {
  const items = (res.data.items || []).filter((val) => (filters.roles && filters.roles?.length ? filters.roles.includes(val.permissionSet.defaultRole) : true));
  return {
    ...res,
    data: {
      ...res.data,
      items
    },
  };
});
const actions = {
  [EMPLOYEES_LIST_REQUEST]({ commit, dispatch, state }, filters) {
    commit(EMPLOYEES_LIST_REQUEST);
    return fetchEmployeesList(filters)
      .then((resp) => {
        commit(EMPLOYEES_LIST_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(EMPLOYEES_LIST_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [EMPLOYEE_DETAILS_REQUEST]({ commit }, grantId) {
    commit(EMPLOYEE_DETAILS_REQUEST);
    return axios.post(
      '/insurance/authorization/grant/detail',
      { grantId },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(EMPLOYEE_DETAILS_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(EMPLOYEE_DETAILS_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [EMPLOYEE_SAVE_REQUEST]({ commit }, grant) {
    commit(EMPLOYEE_SAVE_REQUEST);
    return axios.post(
      '/insurance/authorization/grant/save',
      {
        ...grant,
      },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(EMPLOYEE_SAVE_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(EMPLOYEE_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [EMPLOYEE_DELETE_REQUEST]({ commit }, grantIds) {
    commit(EMPLOYEE_DELETE_REQUEST);
    return axios.post(
      '/insurance/authorization/grant/delete',
      { grantIds },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(EMPLOYEE_DELETE_SUCCESS);
        return true;
      })
      .catch((err) => {
        commit(EMPLOYEE_DELETE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [EMPLOYEE_SEND_WELCOME_EMAIL_REQUEST]({ commit, dispatch }, data) {
    return axios.post(
      '/insurance/authorization/grant/invite',
      data,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(EMPLOYEE_SEND_WELCOME_EMAIL_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
};

const mutations = {
  [EMPLOYEES_LIST_REQUEST]: (state) => {
    state.employeesList.loading = !state.employeesList.loading;
  },
  [EMPLOYEES_LIST_SUCCESS]: (state, resp) => {
    state.employeesList.loading = false;
    state.employeesList = resp;
  },
  [EMPLOYEE_DETAILS_REQUEST]: (state) => {
    state.employeeDetails = null;
  },
  [EMPLOYEE_DETAILS_SUCCESS]: (state, resp) => {
    state.employeeDetails = resp;
  },

  [EMPLOYEE_SAVE_REQUEST]: (state) => {
  },
  [EMPLOYEE_SAVE_SUCCESS]: (state, resp) => {
    state.employeeDetails = resp;
  },
  [EMPLOYEE_DELETE_REQUEST]: (state, resp) => {
  },
  [EMPLOYEE_DELETE_SUCCESS]: (state, resp) => {
  },
  [EMPLOYEE_SEND_WELCOME_EMAIL_SUCCESS]: (state, resp) => {
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
