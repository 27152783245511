var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AdminPageTitle',[_vm._v(" "+_vm._s(_vm.$t("adminTitles.programs"))+" ")]),_c('div',{staticClass:"programs-toolbar"},[_c('div',{staticClass:"programs-filters"},[_c('InputSearch',{attrs:{"value":_vm.searchKey},on:{"searchUpdate":function($event){_vm.searchKey = $event}}}),_c('BaseDropdown',{attrs:{"selectedOptions":_vm.filters.insuranceCompanyIds ? _vm.filters.insuranceCompanyIds : [],"options":_vm.filteredInsuranceCompanies,"multiple":true,"width":'200px',"placeholder":_vm.$t('adminTitles.insuranceCompanyShort')},on:{"changeOptions":function($event){return _vm.updateFilterValue('insuranceCompanyIds', $event)}}}),_c('BaseDropdown',{attrs:{"selectedOptions":_vm.statusFilterValue,"options":_vm.activeList,"multiple":false,"width":'200px',"placeholder":_vm.$t('adminTitles.status')},on:{"changeOptions":_vm.updateActiveFilter}}),_c('BaseDropdown',{attrs:{"selected-options":_vm.filters.insuranceTypes,"multiple":true,"width":'200px',"clearable":true,"placeholder":_vm.$t('programTypes.InsuranceType'),"options":_vm.INSURANCE_TYPES_OPTIONS},on:{"changeOptions":function($event){return _vm.updateFilterValue('insuranceTypes', $event)}}})],1),(_vm.checkUserPermissions([_vm.PermissionsTypes.PLANS_CREATE]))?_c('BaseButton',{on:{"click":function($event){_vm.activeModal = 'newIndividualPlan'}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.newProgram"))+" ")]):_vm._e()],1),_c('div',{staticClass:"programs-table"},[(_vm.isDataLoading && !_vm.programsList.items.length)?_c('el-skeleton',{attrs:{"rows":20}}):(_vm.programsTableData)?_c('BaseTable',{attrs:{"headers":_vm.programsTableHeaders,"data":_vm.programsTableData,"row-clickable":false},scopedSlots:_vm._u([{key:"Name",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"body-text color--purple cursor__pointer",on:{"click":function($event){return _vm.openProgram(row)}}},[_vm._v(" "+_vm._s(row.Name)+" "),(row.program.insuranceType)?_c('span',[_vm._v(" ("+_vm._s(_vm.$t(_vm.INSURANCE_TYPES_NAMES[row.program.insuranceType]))+") ")]):_vm._e()])]}},{key:"Status",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table__cell-status",on:{"click":function($event){return $event.stopPropagation()}}},[_c('div',{staticClass:"row-status body-text color--black-purple",class:row.IsAvailable ? 'status-green' : 'status-orange'},[_vm._v(" "+_vm._s(row.IsAvailable ? _vm.$t("adminTitles.Active") : _vm.$t("adminTitles.inactive"))+" ")]),(_vm.checkOptionalUserPermissions([_vm.PermissionsTypes.PLANS_UPDATE]))?_c('InputSwitcher',{staticClass:"table__cell-status-switcher",attrs:{"checked":row.IsAvailable},on:{"change":function($event){return _vm.togglePlanStatus(row)}}}):_vm._e()],1)]}},{key:"Services",fn:function(ref){
var row = ref.row;
return [(row.offerGenerationStatus !== 'IN_PROCESS')?_c('span',[_vm._v(_vm._s(row.Services))]):_vm._e(),(row.offerGenerationStatus === 'IN_PROCESS')?_c('BasePreloader',{attrs:{"size":'m',"color":'purple'}}):_vm._e()]}},{key:"DeleteBtn",fn:function(ref){
var row = ref.row;
return [(_vm.checkOptionalUserPermissions([_vm.PermissionsTypes.PLANS_DELETE]))?_c('div',{staticClass:"delete-btn"},[_c('img',{attrs:{"src":"/img/renova-dashboard/icons/trash.svg","alt":"delete"},on:{"click":function($event){_vm.planForDelete = row}}})]):_vm._e()]}}])},[_c('BaseTableColumn',{attrs:{"name":"Name"}},[_vm._v(_vm._s(_vm.$t("adminTitles.name")))]),_c('BaseTableColumn',{attrs:{"name":"Provider"}},[_vm._v(_vm._s(_vm.$t("adminTitles.insuranceCompanyShort"))+" ")]),_c('BaseTableColumn',{attrs:{"name":"Services"}},[_vm._v(_vm._s(_vm.$t("adminTitles.services")))]),_c('BaseTableColumn',{attrs:{"name":"People"}},[_vm._v(_vm._s(_vm.$t("adminTitles.people")))]),_c('BaseTableColumn',{staticStyle:{"width":"150px"},attrs:{"name":"Status"}},[_vm._v(_vm._s(_vm.$t("adminTitles.status"))+" ")]),_c('BaseTableColumn',{staticStyle:{"width":"50px"},attrs:{"name":"DeleteBtn"}})],1):_c('NoResultsPlaceholder')],1),(_vm.editableCompany)?_c('ModalCreateNewCompany',{attrs:{"companyEdit":_vm.editableCompany},on:{"close":function($event){_vm.editableCompany = false},"saved":function($event){_vm.initData();
        _vm.editableCompany = null;},"deleted":function($event){_vm.initData();
        _vm.editableCompany = null;}}}):_vm._e(),(_vm.activeModal === 'newCorporatePlan')?_c('ModalCreateNewCorporatePlan',{on:{"close":function($event){_vm.activeModal = null}}}):_vm._e(),(_vm.activeModal === 'newIndividualPlan')?_c('ModalCreateNewIndividualPlan',{on:{"close":function($event){_vm.activeModal = null}}}):_vm._e(),(_vm.planForDelete)?_c('BaseDialog',{attrs:{"width":'444px',"text-align":'left',"footer-align":'flex-end',"title":_vm.$t('adminTitles.DeletePlanTitle'),"text":_vm.$t('adminTitles.DeletePlanText', { name: _vm.planForDelete.Name }),"confirm-button-text":_vm.$t('adminTitles.Delete'),"cancel-button-text":_vm.$t('adminTitles.goBack'),"confirm-loading":_vm.deleteBtnLoading},on:{"close":function($event){_vm.planForDelete = null;
        _vm.deleteBtnLoading = false;},"confirm":function($event){return _vm.deletePlan(_vm.planForDelete)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }