<template>
  <section>
    <BaseSideModal :width="'800px'" class="employee-modal" @close="$emit('close')" :confirm-close-dialog="true">
      <template #title>
        <span>{{ $t("notifications.Notifications") }}</span>
      </template>
      <template #footer>
        <BaseButton type="outline" @click="$emit('close')">
          {{ $t("adminTitles.cancel") }}
        </BaseButton>
        <BaseButton :loading="btnLoading" @click="submitForm">
          {{ $t("buttons.save") }}
        </BaseButton>
      </template>
      <div class="notification-settings">
        <p class="h3 mb_20 flex flex-jc-sb flex-ac">
          {{ $t("notifications.NotificationSettings") }}
          <InputSwitcher
            class="notification-settings__switcher"
            :checked="someNotificationsEnabled()"
            @change="toggleSwitcherNotifications()"
          >
          </InputSwitcher>
        </p>
        <table
          :class="{
            'notification-table_disabled': !someNotificationsEnabled(),
          }"
          class="notification-table"
        >
          <thead>
            <tr>
              <th @click="toggleAllNotifications()" class="notification-table_td">
                <el-checkbox
                  class="mr_8"
                  :value="allNotificationsEnabled()"
                  @change="toggleAllNotifications()"
                >
                </el-checkbox>
                <span class="caption color--black-purple-">{{
                  $t("notifications.EnableAll")
                }}</span>
              </th>
              <th @click="toggleNotificationsInGroup('browser')" class="notification-table_td">
                <el-checkbox
                  class="mr_8"
                  :value="notificationsInGroupSelected('browser')"
                  @change="toggleNotificationsInGroup('browser')"
                >
                </el-checkbox>
                <span class="caption color--black-purple-">{{ $t("notifications.Browser") }}</span>
              </th>
              <th @click="toggleNotificationsInGroup('email')" class="notification-table_td">
                <el-checkbox class="mr_8" :value="notificationsInGroupSelected('email')">
                </el-checkbox>
                <span class="caption color--black-purple-">{{ $t("notifications.Email") }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(notification, key) in notifications" :key="key">
              <td @click="selectAllInKey(key, notification)" class="notification-table_td">
                <el-checkbox class="mr_8" :value="notificationInKeyEnabled(notification)">
                </el-checkbox>
                <span class="caption color--black-purple">{{ notification.label }}</span>
              </td>
              <td
                @click="notification.browser = !notification.browser"
                class="notification-table_td"
              >
                <el-checkbox v-model="notification.browser"></el-checkbox>
              </td>
              <td @click="notification.email = !notification.email" class="notification-table_td">
                <el-checkbox v-model="notification.email"></el-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </BaseSideModal>
  </section>
</template>

<script>
import BaseSideModal from '@/views/RenovaDashboard/components/ui-kit/BaseSideModal/BaseSideModal';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import { mapGetters } from 'vuex';
import InputSwitcher from '@/views/RenovaDashboard/components/ui-kit/Inputs/InputSwitcher';

export default {
  name: 'NotificationsSettingsModal',
  components: {
    InputSwitcher,
    BaseButton,
    BaseSideModal,
  },
  computed: {
    ...mapGetters(['userNotificationsSettings', 'selectedInsuranceProviderDetails']),
  },
  data() {
    return {
      btnLoading: false,
      enableAll: false,
      notifications: {
        // newTask: {
        //   label: this.$t('notifications.newTask'),
        //   email: false,
        //   browser: true,
        // },
        // taskAssigned: {
        //   label: this.$t('notifications.taskAssigned'),
        //   email: false,
        //   browser: true,
        // },
        // taskExpired: {
        //   label: this.$t('notifications.taskExpired'),
        //   email: false,
        //   browser: true,
        // },
        leadCreated: {
          label: this.$t('notifications.leadCreated'),
          email: false,
          browser: true,
        },
        // leadMessageCreated: {
        //   label: this.$t('notifications.leadMessageCreated'),
        //   email: false,
        //   browser: true,
        // },
        ticketCreated: {
          label: this.$t('notifications.ticketCreated'),
          email: false,
          browser: true,
        },
        // ticketMessageCreated: {
        //   label: this.$t('notifications.ticketMessageCreated'),
        //   email: false,
        //   browser: true,
        // },
      },
    };
  },
  created() {
    this.setDefaultNotifications();
  },
  methods: {
    setDefaultNotifications() {
      const userNotifications = this.userNotificationsSettings.notification;
      Object.keys(userNotifications).forEach((key) => {
        if (this.notifications[key]) {
          this.notifications[key] = {
            ...this.notifications[key],
            email: userNotifications[key].email,
            browser: userNotifications[key].browser,
          };
        }
      });
    },
    notificationInKeyEnabled(notification) {
      return notification.email || notification.browser;
    },
    selectAllInKey(key, notification) {
      const notificationInKeyEnabled = this.notificationInKeyEnabled(notification);
      this.notifications[key] = {
        ...this.notifications[key],
        email: !notificationInKeyEnabled,
        browser: !notificationInKeyEnabled,
      };
    },
    toggleAllNotifications() {
      const enableAll = !this.allNotificationsEnabled();
      Object.keys(this.notifications).forEach((key) => {
        this.notifications[key].email = enableAll;
        this.notifications[key].browser = enableAll;
      });
    },
    allNotificationsEnabled() {
      return Object.values(this.notifications).every(
        (notification) => notification.email && notification.browser
      );
    },
    notificationsInGroupSelected(group) {
      return Object.values(this.notifications).every((notification) => notification[group]);
    },
    toggleNotificationsInGroup(group) {
      const groupSelected = this.notificationsInGroupSelected(group);
      Object.keys(this.notifications).forEach((key) => {
        this.notifications[key][group] = !groupSelected;
      });
    },
    someNotificationsEnabled() {
      return Object.values(this.notifications).some(
        (notification) => notification.email || notification.browser
      );
    },
    toggleSwitcherNotifications() {
      const enableAll = !this.someNotificationsEnabled();
      Object.keys(this.notifications).forEach((key) => {
        this.notifications[key].email = enableAll;
        this.notifications[key].browser = enableAll;
      });
    },
    async submitForm() {
      this.btnLoading = true;
      await this.$store.dispatch('saveUserSettings', { notification: this.notifications });
      setTimeout(() => {
        this.$store.dispatch('fetchUserSettings', this.selectedInsuranceProviderDetails.id);
        this.$emit('close');
        this.$notify.success({
          message: `${this.$t('adminTitles.success')}`,
        });
        this.btnLoading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.toggle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.notification-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  border: 1px solid var(--solid-lavender-grey);

  &_disabled {
    opacity: 0.5;
  }

  tr {
    transition: 0.3s;

    &:hover {
      background-color: var(--solid-light-purple);
    }
  }

  th,
  td {
    padding: 14px 16px;
    text-align: left;
    border-bottom: 1px solid #ccc;

    .el-checkbox {
      margin-right: 8px !important;
    }
  }

  th {
    font-weight: bold;
  }

  .notification-settings__switcher {
    cursor: pointer;
  }
}

.notification-table_td {
  cursor: pointer;

  .el-checkbox {
    pointer-events: none;
  }
}
</style>
