/* eslint-disable no-shadow,no-param-reassign */
import axios from 'axios';
import { PermissionsTypes } from '@/utils/permissions';
import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_SAVE_USEDESK_TOKEN,
  USER_SAVE, HR_USER_REQUEST,
} from '../actions/user';
import { AUTH_LOGOUT } from '../actions/auth';

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  DOCTOR: 'DOCTOR',
  INSURANCE_OPERATOR: 'OPERATOR',
  AGENT: 'AGENT',
  HR: 'HR',
};
export const getUserRole = (state) => {
  const domain = state.user.domains.find((domain) => domain.isCurrent);
  return domain.permissionSet.defaultRole;
};

export const getUserDoctorId = (user) => {
  const domain = user.domains.find((domain) => domain.isCurrent);
  return domain?.doctorDetails?.doctorId;
};
const state = {
  status: 'loading',
  userLoaded: false,
  user: {},
  permissions: [],
  selectedInsuranceProvider: null,
  selectedInsuranceProviderDetails: null,
  insuranceProviders: [],
};
const getUserPermissions = (state) => state?.user?.domains?.find((domain) => domain.id === state.selectedInsuranceProvider).permissions;
const getters = {
  user: (state) => state.user,
  userLoaded: (state) => state.userLoaded,
  defaultLocale: (state) => state.user?.settings?.defaultLocale,
  isUserLoading: (state) => state.status === 'loading',
  permissions: (state) => getUserPermissions(state),
  isUserSuperAdmin: (state) => state.user.permissions?.isSuperAdmin,
  userRole: (state) => getUserRole(state),
  userDoctorId: (state) => getUserDoctorId(state.user),
  getUserId: (state) => state.user.id,
  isProfileLoaded: (state) => !!state.user.phone || !!state.user.email,
  selectedInsuranceProviderDetails: (state) => state.selectedInsuranceProviderDetails,
  selectedInsuranceProvider: (state) => state.selectedInsuranceProvider,
  insuranceProviders: (state) => state.insuranceProviders,
};

const actions = {
  [USER_REQUEST]({ commit, dispatch }, providerId) {
    commit(USER_REQUEST);
    const savedUserId = localStorage.getItem('userId');
    return axios
      .post(
        '/insurance/user/detail',
        {},
        {
          headers: {
            'X-Insurance-Provider-ID':
              providerId
              || state.selectedInsuranceProvider
              || localStorage.getItem(`${savedUserId}_insuranceProvider`)
          },
        }
      )
      .then((response) => {
        commit(USER_SUCCESS, response.data);

        return response;
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          dispatch(AUTH_LOGOUT);
        }
        commit(USER_ERROR);

        return Promise.reject(err);
      });
  },
  [HR_USER_REQUEST]({ commit, dispatch }, providerId) {
    commit(USER_REQUEST);
    const savedUserId = localStorage.getItem('userId');
    return axios
      .get(
        '/user/info',
        {},
        {
          headers: {
            'X-Insurance-Provider-ID':
              providerId
              || state.selectedInsuranceProvider
              || localStorage.getItem(`${savedUserId}_insuranceProvider`)
          },
        }
      )
      .then((response) => {
        commit(USER_SUCCESS, response.data);

        return response;
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          dispatch(AUTH_LOGOUT);
        }
        commit(USER_ERROR);

        return Promise.reject(err);
      });
  },
  [USER_SAVE_USEDESK_TOKEN]({ commit, dispatch }, token) {
    return axios
      .post('/user/usedesk/token/save', token)
      .then((resp) => resp)
      .catch(() => Promise.reject());
  },
  [USER_SAVE]({ commit, dispatch }, user) {
    return axios
      .put('/user/info', user)
      .then((resp) => {
        dispatch(USER_REQUEST);
      })
      .catch(() => Promise.reject());
  },
  changeSelectedInsuranceProvider({ commit }, providerId) {
    commit('SET_SELECTED_INSURANCE_PROVIDER', providerId);
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    // state.user = {};
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, user) => {
    const userId = user.profile?.userId || user.id;
    state.user = {
      ...user,
      id: userId,
    };
    localStorage.setItem('userId', userId);
    state.userLoaded = true;
    const insuranceProviders = user.domains;
    const selectedProvider = localStorage.getItem(`${userId}_insuranceProvider`)
      || (insuranceProviders.length ? insuranceProviders[0]?.id : null);
    state.insuranceProviders = insuranceProviders;
    state.selectedInsuranceProvider = selectedProvider;
    state.selectedInsuranceProviderDetails = insuranceProviders.find(
      (provider) => provider.id === selectedProvider
    );
    state.status = 'success';
  },
  [USER_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.user = {};
    state.userLoaded = false;
  },
  SET_SELECTED_INSURANCE_PROVIDER(state, providerId) {
    state.selectedInsuranceProvider = providerId;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
