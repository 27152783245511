<template>
  <BaseOverlay @click.native.self.exact="closeModal">
    <div class="side-modal" :style="style">
      <div class="side-modal__header modal-title">
        <slot name="title" />
        <div v-if="showCloseButton" class="side-modal__close" @click="closeModal">
          <img class="side-modal__icon-cross" src="/img/renova-dashboard/icons/x.svg" alt="x" />
        </div>
      </div>
      <div class="side-modal__content" :class="{ 'side-modal__content--fullHeight': !showFooter }">
        <slot />
      </div>
      <div v-if="showFooter" class="side-modal__footer">
        <slot name="footer" />
      </div>
    </div>
    <BaseDialog
      v-if="confirmDialogOpen"
      :width="'444px'"
      :text-align="'left'"
      :footer-align="'flex-end'"
      :title="$t('adminTitles.AreYouSureToCancelOperation')"
      :text="$t('adminTitles.AllUnsavedDataWillBeLost')"
      :confirm-button-text="$t('adminTitles.continue')"
      :cancel-button-text="$t('buttons.cancel')"
      @close="confirmDialogOpen = null"
      @confirm="$emit('close')"
    />
  </BaseOverlay>
</template>

<script>
import BaseOverlay from '@/views/RenovaDashboard/components/ui-kit/BaseOverlay';
import BaseDialog from '@/views/RenovaDashboard/components/ui-kit/BaseDialog';

export default {
  name: 'BaseSideModal',
  components: {
    BaseDialog,
    BaseOverlay,
  },
  emits: ['close'],
  props: {
    width: {
      type: String,
      default: '500px',
      required: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    confirmCloseDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmDialogOpen: false,
    };
  },
  computed: {
    style() {
      return {
        'max-width': this.width,
      };
    },
  },
  methods: {
    closeModal() {
      if (this.confirmCloseDialog) {
        this.confirmDialogOpen = true;
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: var(--white);
  z-index: 1001;
  overflow-y: auto;
  background-color: #ffffff;

  &__header {
    border-bottom: 1px solid var(--solid-lavender-grey);
    color: var(--solid-black-purple);
    margin: 16px 24px 24px;
    padding-bottom: 16px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 22px;

    &-icon-cross {
      width: 20px;
      height: 20px;
    }
  }

  &__content {
    padding: 0 24px;
    height: calc(100vh - 172px);
    overflow: hidden;
    overflow-y: scroll;

    &--fullHeight {
      height: calc(100vh - 80px);
    }
  }

  &__footer {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    box-shadow: 0px -4px 4px 0px #00000005;
  }
}
</style>
