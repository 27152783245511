import axios from 'axios';

const state = {
  permissionSetList: null,
  permissionSet: null,
};

const getters = {
  permissionSetList: () => state.permissionSetList,
};
export function getPermissionSetList() {
  return axios.post('/insurance/authorization/permission-set/list', {});
}
export function fetchPermissionSetDetails(permissionSetId) {
  return axios.post('/insurance/authorization/permission-set/detail', {permissionSetId});
}
const actions = {
  fetchPermissionSetList({commit, state}) {
    return getPermissionSetList(state.filters).then((response) => {
      commit('setPermissionSetList', response.data.items);
    }).catch((error) => {
      console.error('Error fetching tasks:', error);
    });
  },
  savePermissionSet({commit, dispatch}, body) {
    return axios.post('/insurance/authorization/permission-set/save', {
      ...body
    });
  },
  deletePermissionSet({commit, dispatch}, permissionSetId) {
    axios.post('/insurance/authorization/permission-set/delete', {
      permissionSetIds: [permissionSetId],
    })
      .then((response) => {
        dispatch('fetchPermissionSetList');
      })
      .catch((error) => {
        console.error('Error fetching task details:', error);
      });
  },

  updatePermissionSetFilters({commit}, filters) {
    commit('setFilters', filters);
  },

  clearFilters({commit}) {
    commit('clearAllFilters');
  }
};

const mutations = {
  setPermissionSetList(state, permissionSetList) {
    state.permissionSetList = permissionSetList;
  },
  setPermissionSetDetails(state, details) {
    state.permissionSet.data = details;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
